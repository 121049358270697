<template>
  <div class="flex justify-between md:space-x-4 lg:space-x-8 xl:space-x-36 py-12 md:py-24">
    <div class="flex-1 lg:pt-12 xl:pt-0 xl:self-center xl:pl-28 md:max-w-lg">
      <h2 class="font-bold text-2xl xl:text-3xl 2xl:text-4xl text-blue-800 mb-2 xl:mb-4">DSM Proteases <br>Matrix Calculator</h2>
      <h3 class="text-xl md:text-3xl mb-2 xl:mb-4 text-left">Login to your account</h3>
      <p class="text-xs lg:text-sm text-grey-dark mb-4 xl:mb-8 2xl:mb-12">Access your account for the ProAct360 and Ronozyme ProAct calculator.</p>
      <div
        v-if="errors"
        class="mb-3 bg-red-300 border border-red-600 rounded-xl p-3 md:py-3 md:px-5 font-medium text-sm text-red-600">
        <template v-if="errors.email">
          <span
            class="icon md:mr-3.5"
            v-html="icons.info"
          ></span>
          {{ errors.email[0] }}
        </template>

        <template v-if="errors.email_verified_at">
          <span
            class="icon md:mr-3.5"
            v-html="icons.info"
          ></span>
          {{ errors.email_verified_at[0] }} <router-link :to="{name: 'request-verification-email'}" class="link font-medium text-brand-semiMedium underline hover:no-underline">use this</router-link>
        </template>
      </div>
      <form @submit.prevent="onSubmit">
        <div class="row flex space-x-4 md:space-x-8 mb-5">
          <form-field-input
            v-model.trim="email"
            class="flex-1"
            size="medium"
            type="email"
            field-label="E-mail Address *"
            placeholder="example@dsm.com"
            :validation-class="{invalid: $v.email.$error, valid: !$v.email.$invalid}"
            @blur="$v.email.$touch()"
          >
            <template #helper v-if="$v.email.$error">
              <span
                v-if="!$v.email.required"
                class="invalid"
              >
                Email can't be empty
              </span>

              <span
                v-if="!$v.email.email"
                class="invalid"
              >
                  Email isn't valid
              </span>

              <span
                v-if="errors['email'] && errors['email'].length"
                class="invalid"
              >
                  errors['email'][0]
              </span>
            </template>
          </form-field-input>
        </div>

        <div class="row flex space-x-4 md:space-x-8 mb-5">
          <form-field-input
            v-model.trim="password"
            class="flex-1"
            size="medium"
            type="password"
            field-label="Password *"
            :validation-class="{invalid: $v.password.$error, valid: !$v.password.$invalid}"
            @blur="$v.password.$touch()"
          >
            <template #helper v-if="$v.password.$error">
              <span
                v-if="!$v.password.required"
                class="invalid"
              >
                Password can't be empty
              </span>

              <span
                v-if="!$v.password.minLength"
                class="invalid"
              >
                min Length is 6
              </span>
            </template>
          </form-field-input>
        </div>

        <div class="row flex justify-end mt-4 mb-8">
          <router-link
            :to="{name: 'reset-password'}"
            class="link font-medium text-brand-semiMedium hover:underline text-sm"
          >
            Forgot your password?
          </router-link>
        </div>

        <div class="row">
          <button type="submit" class="btn-success w-full">Login</button>
        </div>

        <div class="row pt-4 border-t border-grey-mediumLight mt-9">
          <p class="m-0 text-center text-sm">
            Don't have an account?
            <router-link
              :to="{name: 'register'}"
              class="link font-medium text-brand-semiMedium underline hover:no-underline"
            >Sign up</router-link>
          </p>
        </div>


      </form>
    </div>

    <div class="img flex-1 hidden md:block relative">
      <img src="~@/assets/images/page-img-round.png" alt="">
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import { validationMixin } from 'vuelidate';
import {mapActions, mapMutations} from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'Login',

  data: () => ({
    email: '',
    password: '',
    errors: null
  }),

  components: {
    FormFieldInput
  },

  mixins: [validationMixin],

  validations: {
    email: {
      required,
      email
    },

    password: {
      required,
      minLength: minLength(6)
    }
  },

  mounted() {
    this.$store.commit('setNavigation')
    this.setLoading(false)
  },

  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),

    ...mapActions({
      signIn: 'auth/signIn'
    }),

    onSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const formData = {
        email: this.email,
        password: this.password
      }

      this.signIn(formData)
          .then(() => {
            this.errors = []
            this.$router.push({name: 'product-selection'})
          })
          .catch((err) => {
            console.log(err.data)
            this.errors = err.data.errors
            // TODO: error handling
          })

      return formData
    }
  },
};
</script>

<style scoped>
</style>
